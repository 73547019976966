import React from 'react';
import { Link, useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';

import About from '../components/about';
import Layout from '../components/layout';
import SEO from '../components/seo';

const IndexPage = () => {
  const data = useStaticQuery(graphql`
    query {
      aboutImage: file(relativePath: { eq: "atelier.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1200) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  return (
    <Layout>
      <SEO
        title="About"
        description="The work of Rio de Janeiro artist Katharina Welper is linked to a
          craft which is almost obsessive and which sees memory as a defining
          locus for the development of its poetry."
      />
      <div className="page">
        <div className="about-section">
          <About full />
        </div>
        <div className="publications">
          <h3>Publications</h3>
          <ul>
            <li>
              <Link to="/publications/about-everyday-domesticity">
                About everyday domesticity
              </Link>
            </li>
          </ul>
          <div className="page-image">
            <Img fluid={data.aboutImage.childImageSharp.fluid} />
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default IndexPage;
